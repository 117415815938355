<template lang='pug'>
    #modal-onboard-new-user.haq-modal.modal.no-autoinit(data-dismissible="false")
        .modal-content
            .modal-header
                img.onboarding-logo(:src="wlLogo")
            .modal-body
                .steps
                    .illustration
                        img(src="/images/onboarding/illustration.png")
                    .message
                        .step(v-if="step==0") {{'Welcome to '+wlData.name+'! Our mission is to give you and your guests confidence in the air quality and energy usage of your building.'}}
                        .step(v-if="step==1") {{wlData.name+' provides you with many unique tools to help communicate peace of mind to guests and give you the inside scoop on any potential problem areas.'}}
                        .step(v-if="step==2")
                            span 
                                | By clicking "Get Started" below, you agree that you have read and accept our 
                                a(href="https://gethawken.com/privacy-policy" target="_blank") Privacy Policy 
                                | and 
                                a(href="https://gethawken.com/terms-of-service" target="_blank") Terms of Service
                                | .
                            v-checkbox(v-model="agree" label="I agree to the Privacy Policy and Terms of Service")

                    .step-progress
                        .step(v-if="step==0")
                        .step(v-if="step==1")
                        .step(v-else)
                    .buttons
                        .step(v-if="step==0")
                            .btn(@click="nextStep") Next
                            //- .skip(@click="completeOnboarding") Skip
                        .step(v-if="step==1")
                            .btn(@click="nextStep") Next
                        .step(v-if="step==2")
                            .btn(@click="completeOnboarding" :disabled="!agree") Get Started
</template>
<script>
import {wlData} from '../../assets/js/constants';
import {HTTPClient} from '@/assets/js/http';
let client = new HTTPClient();

export default {
    name: 'ModalWelcome',
    mounted() {
        try{
            console.log('ModalWelcome mounted');
            let modal = document.getElementById("modal-onboard-new-user");
            
            // Initialize the modal with the proper options to prevent closing
            M.Modal.init(modal, {
                dismissible: false,
                onCloseEnd: () => {
                    // Only allow closing if the user has agreed
                    if (!this.agree) {
                        // Reopen the modal if they haven't agreed
                        setTimeout(() => {
                            let instance = M.Modal.getInstance(modal);
                            if (instance) {
                                instance.open();
                            }
                        }, 100);
                    }
                }
            });
            
            // Open the modal
            let instance = M.Modal.getInstance(modal);
            if (instance) {
                instance.open();
            }
            
            // Add a click event listener to the document to prevent closing by clicking outside
            document.addEventListener('click', (e) => {
                const onboardingComplete = (this.user || this.$store.state.user)?.onboardingComplete?.modals;
                if(onboardingComplete) return;
                if (!this.agree && modal.contains(e.target) === false) {
                    e.stopPropagation();
                    return false;
                }
            }, true);
            
        } catch(e) {
            console.error('ModalWelcome mounted error', e);
        }
    },
    props: {
        logo: String,
        user: Object,
    },
    data() {
        return {
            wlLogo: wlData.loginLogo ? wlData.loginLogo : "/images/hawkenAqBlue.png",
            wlData,
            step: 0,
            agree: false
        }
    },
    methods: {
        closeModal(){
            let modal = document.getElementById("modal-onboard-new-user");
            let instance = M.Modal.getInstance(modal);
            if (instance) {
                instance.close();
            }
        },
        nextStep() {
            this.step++;
        },
        completeOnboarding(){
            let user = this.user || this.$store.state.user;

            if(!user.onboardingComplete)
                user.onboardingComplete = { "modals": {} };
            else if(!user.onboardingComplete?.modals)
                user.onboardingComplete.modals = {};
            
            let newOnboardingStep = {
                "step": this.step,
                "name": "welcome and Accept",
                "complete": true
            }
            user.onboardingComplete.modals[this.step] = newOnboardingStep;
            user.acceptTerms = true;
            
            client.robustPost(`/api/users/${user.objectId}`,user).then(function(result){
                console.log('User Updated',result);
            }).catch(function(error){
                console.log("User Not Updated", error);
            })
            this.closeModal();
        },
    },
};
</script>
<style lang="sass" scoped>
    #modal-onboard-new-user
        max-width: 724px
        padding: 32px
        width: 100%
        .modal-header
            display: flex
            justify-content: space-between
            border-bottom: none !important
            margin: 0 0 24px !important
            padding: 0 !important
            .onboarding-logo
                max-width: 200px
            .close
                cursor: pointer
                font-size: 38px
        .modal-body 
            margin: 0 !important
            .steps
                margin: 0 auto
                max-width: 354px
                text-align: center
                width: 100%
                .illustration
                    margin: 0 auto 24px
                    max-height: 184px
                    max-width: 200px
                    width: 100%
                .message
                    font: normal normal 18px/24px 'Roboto'
                    margin-bottom: 12px
                .step-progress
                    margin-bottom: 32px
                .btn
                    background: #fff
                    border: 1px solid #fff
                    border-radius: 12px
                    color: #021449
                    font: normal normal 18px/45px 'Roboto'
                    letter-spacing: 0.18px
                    margin-bottom: 16px
                    height: 45px
                    width: 200px
                .skip
                    cursor: pointer
                    font: normal normal 16px/24px 'Roboto'
                    &:hover
                        text-decoration: underline
</style>