<template lang="pug">
	v-dialog(v-model="dialogEditDevice" max-width="792px" scrollable @click:outside="toggleEditDevice")
		v-card(style="background: var(--app-tertiary-bg)")
			v-card-title.mb-4
				span(class="text-h5") Device Info
				v-spacer
				v-icon.close(text @click="toggleEditDevice") mdi-close
			v-card-text
				v-container.mt-4
					v-row
						v-col
							v-text-field.haq-text-field(v-model="deviceDetails.deviceName" label="Device Name")
						v-col
							v-text-field.haq-text-field(v-model="deviceDetails.name" label="Model Name" disabled)
					v-row
						v-col
							v-text-field.haq-text-field(v-model="deviceDetails.serialNum" label="Serial Number" disabled)
						v-col
							v-text-field.haq-text-field(v-model="deviceDetails.rssi" label="RSSI" disabled)
					v-row
						v-col
							//-v-select.haq-text-field(v-model="deviceZone" :items="zones" item-text="name" item-value="objectId" label="Zone" hide-details)
							v-text-field.haq-text-field(v-model="deviceDetails.zone" disabled item-text="name" item-value="objectId" label="Zone" hide-details)
						v-col
							v-text-field.haq-text-field(v-model="deviceDetails.connected" label="Connected" disabled)
					v-row
						v-col
							v-text-field.haq-text-field(v-model="deviceDetails.lastTrendData" label="Last Trend Data" disabled)
				
					.metadata-section.mt-4(v-if="hasMetadata")
						template(v-for="(chunk, index) in metadataChunks")
							v-row(:key="index")
								v-col(v-for="field in chunk" :key="field.key")
									v-text-field.haq-text-field(
										v-model="deviceDetails.metadata[field.key]"
										:label="field.label"
										disabled
									)
			v-card-actions
				v-spacer
				v-btn.haq-btn.haq-btn-secondary(text @click="toggleEditDevice") Cancel
				v-btn.haq-btn.haq-btn-primary(text @click="saveDevice") Save

</template>
<script>
import Navbar from '@/components/Navbar.vue'
import {HTTPClient} from '../../assets/js/http'
let client = new HTTPClient();

export default {
    name: 'DeviceModal',
    components: {
        Navbar,
    },
	props: {
		action: String,
		device: Object,
	},
    data() {
        return {
            siteId: this.$route.query.siteId,
            dialogEditDevice: this.action == 'edit' ? true : false,
			deviceDetails: {...this.device},
			deviceZone: null,
			zones: []
        }
    },
    computed: {
        hasMetadata() {
            return this.deviceDetails?.metadata && 
                Object.keys(this.deviceDetails.metadata).length > 0;
        },
        metadataFields() {
            if (!this.deviceDetails?.metadata) return [];
            
            return Object.entries(this.deviceDetails.metadata)
                .filter(([_, value]) => value !== null && value !== undefined)
                .map(([key, _]) => ({
                    key,
                    label: this.formatLabel(key)
                }));
        },
        metadataChunks() {
            // Split metadata fields into chunks of 2 for displaying in columns
            const chunks = [];
            for (let i = 0; i < this.metadataFields.length; i += 2) {
                chunks.push(this.metadataFields.slice(i, i + 2));
            }
            return chunks;
        }
    },
    methods: {
        formatLabel(key) {
            // Convert camelCase or snake_case to Title Case
            return key
                .replace(/([A-Z])/g, ' $1') // Insert space before capital letters
                .replace(/_/g, ' ') // Replace underscores with spaces
                .replace(/^\w/, c => c.toUpperCase()) // Capitalize first letter
                .trim();
        },
        toggleEditDevice() {
            this.dialogEditDevice = false
			this.$emit('closeDeviceModal')
        },
        async saveDevice() {
            try {
                await client.robustPost(`/api/devices/${this.device.objectId}`, {
					deviceName: this.deviceDetails.deviceName,
				})
				this.toggleEditDevice();
			} catch(e) {
				console.log(e)
			}
        },
		async getZones() {
			try {
				let result = await client.robustQuery(`/api/zones?siteId=${this.siteId}`);
				this.zones = result.results;
				this.deviceZone = this.zones.find(x => x.objectId === this.device.zoneId)?.objectId;
			} catch (error) {
				console.error(error);
			}
		}
    },
    watch: {
        device: {
            immediate: true,
            handler(newDevice) {
                this.deviceDetails = {...newDevice};
            }
        }
    },
    mounted() {
		this.getZones();
    },
}
</script>
<style lang="scss" scoped>
.controls {
    display: flex;
    align-items: center;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .project-name {
        font: normal normal 20px Roboto;
    }
}
.edit-users-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 62px;
    padding: 15px;
    .v-input--selection-controls {
        margin: 0;
        padding: 0;
    }
}

.device-card {
    background: #25294A;
    border-radius: 20px;
    .v-input input {
        background: #141729;
        border-radius: 12px;
    }
    
    .personal-info {
        .edit-photo {
            display: flex;
            align-items: flex-end;
            img.avatar {
                border-radius: 50px;
                margin-right: 20px;
                max-width: 100px;
            }
        }        
        .name-wrp {
            display: flex;
        }
    }
}
.no-users {
    background: #25294A;
    border-radius: 20px;
    box-shadow: 0px 100px 80px rgba(2, 4, 12, 0.12), 0px 41.7776px 33.4221px rgba(2, 4, 12, 0.0862625), 0px 22.3363px 17.869px rgba(2, 4, 12, 0.0715329), 0px 12.5216px 10.0172px rgba(2, 4, 12, 0.06), 0px 6.6501px 5.32008px rgba(2, 4, 12, 0.0484671), 0px 2.76726px 2.21381px rgba(2, 4, 12, 0.0337375);
    margin: 56px auto;
    max-width: 460px;
    padding: 24px;
    text-align: center;
    width: 100%;
}
</style>