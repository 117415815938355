<template lang="pug">
    .aqcard-measurement(:class="{'top-lvl-aqcm': scoreTypes.includes(m.type), 'measurement-only-mode': halfViewMode, 'alerting': ((m.alerts&&m.alerts.length>0) || (scoreTypes.includes(m.type)&&m.zone&&m.zone.activeAlertsCount))}" @click="navigateToLink(m)")
        .aqcard-navigate-wrap
            .aqcard-measurement-title-wrap
                .aqcard-measurement-title
                    span(v-if="scoreTypes.includes(m.type)") {{ topLevelScore(m) }}
                    span(v-else) {{name}}
                .aqcard-measurement-icons
                    v-tooltip(top)
                        template(v-slot:activator="{ on, attrs }")
                            .aqcard-icon-sensor-count(v-if="scoreTypes.includes(m.type)" v-bind="attrs" v-on="on")
                                svg(width="34" height="22" viewBox="0 0 11.011469 6.3500001" xmlns="http://www.w3.org/2000/svg")
                                    g(transform="matrix(0.26458333,0,0,0.26458333,-2.8415713,-5.2186309)")
                                        line(class="cls-1" x1="31.576027" x2="31.576027" y1="27.939205" y2="29.374184")
                                        path(class="cls-1" d="m 18.840601,25.270149 a 10.224215,10.224215 0 0 0 0,12.340806")
                                        path(class="cls-1" d="m 15.116835,21.158937 a 19.429596,19.429596 0 0 0 0,20.563229")
                                        path(class="cls-1" d="m 44.246879,37.610955 a 10.224215,10.224215 0 0 0 0,-12.340806")
                                        path(class="cls-1" d="m 47.970646,41.722166 a 19.372197,19.372197 0 0 0 0,-20.563229")
                                        rect(class="cls-1" height="20.089685" width="15.785205" x="23.683649" y="22.199297")
                                | {{m.sensorCount ? m.sensorCount : (m.childMeasurements?.length || 0)}}
                        span Measurements in this Zone
                    SectionEditor(:text="name" title="Measurement Name" :measurementId="m.objectId" v-on:updateText="updateMeasurementText" v-if="zoneId!=null")
                    .aqcard-icon-measurement(v-if="!scoreTypes.includes(m.type)")
                        MeasurementIcons(:measurement="m.type.toLowerCase()", :size="32")
                    .aqcard-icon-warning(v-if="!scoreTypes.includes(m.type) && !m.chartData || m.isAlerting")
                        v-tooltip(top)
                            template(v-slot:activator="{ on, attrs }")
                                i.material-icons(v-bind="attrs" v-on="on") warning
                            span This sensor is inactive
                    .writable-status-icons
                        img.new-val-saving(:class="'new-val-saving-' + m.objectId" src="/images/site/simple-loading.gif")
                        v-icon.new-val-saved(:class="'new-val-saved-' + m.objectId") mdi-check-circle													 
                        v-icon.new-val-failed(:class="'new-val-failed-' + m.objectId") mdi-alert-octagon

            .aqcard-measurement-value.donut(v-if="scoreTypes.includes(m.type) && !halfViewMode")
                DonutIndicator(:id="m.type+'card-graph'", :value="m.curVal ? m.curVal : (m.curScore != null ? Math.round(m.curScore) : '-')", :size="50" v-if="m.curScore!=null" v-show="showDonut")
            
            .aqcard-measurement-value
                .writable(v-if="m.writable")
                    .new-val-displaying(:class="'new-val-displaying-' + m.objectId")
                        .writable-val(@click="rewriteValue(m.objectId)") {{ measurementDisplayValue(m) }}
                    .new-val-editing(:class="'new-val-editing-' + m.objectId")
                        v-text-field.edit-val.ev-num(v-if="m.writable.function[0].toLowerCase().includes('num')" label="" v-model="editingCurVal" :value="coerceCurVal(m.curVal,m.kind)" type="number" hide-details)
                        v-switch.edit-val.ev-bool(v-else-if="m.writable.function[0].toLowerCase().includes('bool')" v-model="editingCurVal" :value="coerceCurVal(m.curVal,m.kind)" :label="`Enable?`" :true-value="true" :false-value="false" inset hide-details)
                        v-select.edit-val.ev-str(v-else-if="m.writable.function[0].toLowerCase().includes('str')" :items="items" label="" outlined hide-details)
                        .new-val-edit-btns
                            v-btn.edit-val-cancel.haq-btn.haq-btn-secondary(@click="cancelWritableValue(m.objectId)") Cancel
                            v-btn.edit-val-save.haq-btn.haq-btn-primary(@click="saveWritableValue(m.objectId,m.writable.function[0],m.writable.endpoint)") Save
                .non-writable(v-else v-show="showNonWritable") {{measurementDisplayValue(m)}}
                .aqcard-measurement-unit {{m.unit}}
        
        .aqcard-measurement-graph(v-if="m.chartData && !halfViewMode && showNonWritable")
            figure.highcharts-figure.hide-on-small-only(@click.stop="toggleLrgGraph(m.__ob__.dep.id)")
                AreaChart(:dataArray="m.chartData.data", :colorMode="m.chartData.colorMode", :units="m.unit", :range="m.chartData.range", :enumeration="m.chartData.enumeration", :multiStateValues="m.chartData.multiStateValues")
            figure.highcharts-figure-mobile.show-on-small.hide-on-med-and-up
                AreaChartLarge(:dataArray="m.chartData.data", :colorMode="m.chartData.colorMode", :units="m.unit", :range="m.chartData.range", :enumeration="m.chartData.enumeration", :multiStateValues="m.chartData.multiStateValues", height="200px")
        .aqcard-measurement-graph-warning(v-else)
            //- i.material-icons error_outline
</template>
<script>
import DonutIndicator from './DonutIndicator'
import AreaChart from './AreaChart'
import AreaChartLarge from './AreaChartLarge'
import MeasurementIcons from '@/components/MeasurementIcons.vue';
import {HTTPClient} from '../assets/js/http'
import SectionEditor from './SectionEditor'
import moment from 'moment';
let client = new HTTPClient();
const delay = time => new Promise(res => setTimeout(res, time));

export default {
	name: 'AQMeasurement',
	components: {
		DonutIndicator,
        SectionEditor,
		AreaChart,
		AreaChartLarge,
		MeasurementIcons,
	},
	props: {
		title: String,
		measurements: Array,
		score: Number,
		type: String,
		timePeriod: String,
		tpCustomStart: Number,
		tpCustomEnd: Number,
		zoneTracker: Object,
        m: Object
	},
    data() {
		let startTime;
		let endTime;

		// Should handle start time of clien
		switch(this.timePeriod){
			case "custom": {
				startTime = this.tpCustomStart
				endTime = this.tpCustomEnd
				break;
			}
			case "week": {
				startTime = moment().startOf('week').valueOf()
				break;
			}
			case "day": {
				startTime = moment().startOf('day').valueOf()
				break;
			}
			case "month": {
				startTime = moment().startOf('month').valueOf()
				break;
			}
			default: {
				startTime = moment().startOf('week').valueOf() 
				break;
			}
		}
        // console.log('data', this.timePeriod, new Date(startTime), new Date(endTime));

		return {
			siteId: this.$route.params.siteId,
			zoneId: this.$route.params.zoneId,
            measurementId: this.m.uuid,
            editingCurVal: null,
			expanded: {
				status: false,
				icon: 'keyboard_arrow_down',
				sid: this.$route.params.siteId
			},
			startTime,
			endTime,
			aqCardIndicatorScore: Math.round(this.score),
            halfViewMode: this.$store.state.site.aqEnabled === false && (this.m.type == 'scoreEQ' || this.m.type == 'scoreAQ'),
			scoreTypes: ['scoreOA', 'scoreAQ', 'scoreOW', 'scoreAF', 'scoreEQ'],
            name: this.m.name
		}
	},
	mounted() {
		this.loadTrendCharts(this.m);
        this.editingCurVal = this.coerceCurVal(this.m.curVal, this.m.kind);
		
		// this.measurements.forEach(m =>{
		// 	if (m.writable)
		// 		console.log("this.measurements",m)
		// })
		
	},
	methods: {
        updateMeasurementText(m){
            console.log(m)
            this.name = m
        },
        coerceCurVal(curVal, kind){
            try{
                if(curVal == null){
                    return null;
                }else if(kind == 'Bool'){
                    if(typeof(curVal)=='boolean') return curVal;
                    return curVal == 'false' ? false:true;
                }else if(kind == 'Str'){
                    return curVal;
                }else if(kind == 'Number'){
                    return isNaN(curVal) ? null : JSON.parse(curVal);
                }else{
                    return curVal;
                }
            }catch(e){
                console.error(e);
                return null;
            }
        },
		rewriteValue(measurementId){
			let displayVal = '.new-val-displaying-' + measurementId;
			let editVal = '.new-val-editing-' + measurementId;
			let savedVal = '.new-val-saved-' + measurementId;
			let saveFailedVal = '.new-val-failed-' + measurementId;
			$(displayVal).hide(); // eslint-disable-line no-undef
			$(savedVal).hide(); // eslint-disable-line no-undef
			$(saveFailedVal).hide(); // eslint-disable-line no-undef
			$(editVal).show(); // eslint-disable-line no-undef
		},
		cancelWritableValue(measurementId) {
			let displayVal = '.new-val-displaying-' + measurementId;
			let editVal = '.new-val-editing-' + measurementId;
			$(editVal).hide(); // eslint-disable-line no-undef
			$(displayVal).show(); // eslint-disable-line no-undef
		},
		async saveWritableValue(measurementId,writableFunction,endpoint) {
			let displayVal = '.new-val-displaying-' + measurementId;
			let editVal = '.new-val-editing-' + measurementId;
			let savingVal = '.new-val-saving-' + measurementId;
			let savedVal = '.new-val-saved-' + measurementId;
			let saveFailedVal = '.new-val-failed-' + measurementId;

			$(editVal).hide(); // eslint-disable-line no-undef
			$(savedVal).hide(); // eslint-disable-line no-undef
			$(saveFailedVal).hide(); // eslint-disable-line no-undef
			$(displayVal).show(); // eslint-disable-line no-undef
			$(savingVal).show(); // eslint-disable-line no-undef

			try {
                let cv = this.coerceCurVal(this.editingCurVal, this.m.kind);
				let saveJob = await client.robustPost(`${endpoint}`, {function: writableFunction, arguments: {curVal: cv}});
				if(saveJob.successMessage) {
					let jobId = saveJob.results.jobId;

                    // loop and wait for the job to finish
                    while(true){ // eslint-disable-line no-constant-condition
                        let jobQ = await client.robustQuery(`/api/jobs/${jobId}`);
                        // console.log("job",jobQ);
                        if(jobQ.successMessage&&jobQ.results.status == 'failed') {
                            // job ran but failed
                            this.saveFailed(savingVal,saveFailedVal);
                            window.M.toast({html: jobQ.results.errorMessage||'Point write failed'});
                            break;
                        }else if(jobQ.successMessage&&jobQ.results.status == 'completed') {
                            this.saveSuccess(savingVal,savedVal);
                            this.refreshMeasurement(jobQ.results);
                            break;
                        }else if(jobQ.errorMessage){
                            // job failed to run
                            this.saveFailed(savingVal,saveFailedVal);
                            break;
                        }else{
                            await delay(400);
                        }
                    }
				}
			} catch (error) {
				console.error(error);
				this.saveFailed(savingVal,saveFailedVal);
			}
		},
		saveSuccess(savingVal,savedVal) {
			$(savingVal).hide(); // eslint-disable-line no-undef
			$(savedVal).show(); // eslint-disable-line no-undef
			
			setTimeout(function() {
				$(savedVal).hide(); // eslint-disable-line no-undef
			}, 3000);
		},
		saveFailed(savingVal,saveFailedVal) {
			$(savingVal).hide(); // eslint-disable-line no-undef
			$(saveFailedVal).show(); // eslint-disable-line no-undef
			
			setTimeout(function() {
				$(saveFailedVal).hide(); // eslint-disable-line no-undef
			}, 3000);
		},
		refreshMeasurement(jobResults) {
            if(jobResults.arguments.curVal != null){
                this.m.curVal = String(jobResults.arguments.curVal);
            }
			this.loadTrendCharts(this.m, {force: true});
		},
		expand(){
			if(this.expanded.status){
				this.expanded.status = false;
				this.expanded.icon = 'keyboard_arrow_down'
			} else {
				this.expanded.status = true;
				this.expanded.icon = 'keyboard_arrow_up'
			}
		},
		toggleLrgGraph(id) {
			let g = '#lrg-grph-' + id
			// eslint-disable-next-line no-undef
			$('.aqcard-measurement-large').not(g).hide()
			// eslint-disable-next-line no-undef
			$(g).toggle(0,function() {
				// eslint-disable-next-line no-undef
				$(this).get(0).scrollIntoView({
					behavior: "smooth",
					block: "end"
				});
			})
			
		},
		navigateToLink(m){
			if(this.scoreTypes.includes(m.type))
				this.$emit("aqCardZoneChange", m.zone.objectId);
		},
		async loadTrendCharts(m, options={}){
			let themes = this.$store.state.themes || {};
			try {
                // console.log('loadTrendCharts', m, new Date(this.startTime));
                let chartData = await this.$store.dispatch('getTrendData', {
                    siteId: this.siteId,
                    pointId: m.uuid,
                    version: 'highcharts',
                    startTime: this.startTime,
                    endTime: this.endTime,
                    type: m.type,
                    force: options.force
                });
                if(chartData.data.length > 0){
                    m.chartData = chartData
                    if(m.activeText){
                        let activeText = m.activeText;
                        let inactiveText = m.inactiveText;
                        m.chartData.enumeration = {
                            activeText: m.activeText,
                            inactiveText: m.inactiveText
                        }
                        if(m.kind == "Bool"){
                            if(!isNaN(chartData.data[0][1])){
                                m.chartData.data = chartData.data.map((el) => {
                                    // console.log(new Date(el[0]));
                                    return [el[0], el[1] ? activeText : inactiveText ]
                                })
                            }
                        }
                    }
                    if(m.multiStateValues){
                        m.chartData.multiStateValues = m.multiStateValues;
                    }
                    if(m.curScore > 80){
                        m.chartData.colorMode = themes.ex || "#4e7cff";
                    } else if(m.curScore >= 50 && m.curScore < 80) {
                        m.chartData.colorMode = themes.mo || "#feca12"
                    } else {
                        // console.log(`Score less than 50 `);
                        m.chartData.colorMode = themes.poor || "#fa5f5f"
                        // console.log(`Score less than 50 ` + m.chartData.colorMode);
                    }
                    if((m.alerts&&m.alerts.length>0) || (this.scoreTypes.includes(m.type)&&m.zone&&m.zone.activeAlertsCount))
                        m.chartData.colorMode = '#ffffff';
                }
                if(this.$store.state.site.aqEnabled === false && m.chartData){
                    m.chartData.colorMode = themes.ex || "#4e7cff";
                    if((m.alerts&&m.alerts.length>0) || (this.scoreTypes.includes(m.type)&&m.zone&&m.zone.activeAlertsCount))
                        m.chartData.colorMode = '#ffffff';
                }
                m.ready = true
                this.$forceUpdate();
                // this.expanded.status = false;
                // this.expanded.status = true;
			} catch (error) {
				console.error(error);
			}
		},
		async aqCardIndicatorScoreLatest(){
			try {
				if(this.measurementId == null){
					return null;
				}
				if(this.$store.state.TrendData[this.measurementId]?.data[this.$store.state.TrendData[this.measurementId].data.length-1]){
					this.aqCardIndicatorScore = Math.round(
						this.$store.state.TrendData[this.measurementId].data[this.$store.state.TrendData[this.measurementId].data.length - 1][1]
					);
				} else {
					let measurementIds = JSON.stringify([this.measurementId]);
					let res = await client.robustQuery(`/api/site/${this.siteId}/latest`, {measurementIds});
					if(res.results?.[this.measurementId]?.curScore?.val){
						this.aqCardIndicatorScore = Math.round(res.results[this.measurementId].curScore.val)
					}
				}
			} catch (error) {
				console.error(error);
				this.aqCardIndicatorScore = 100;
			}
		}
	},
	computed: {
		topLevelScore(){
			return (m) => {
				if(m.zone){
					let zt = this.zoneTracker[m.zone.objectId]
					if(zt)
						return zt;
					else
						return name
				}
				return name
			}
		},
		measurementDisplayValue(){
			return (m) => {
				let field = this.scoreTypes.includes(m.type) ? "curScore" : "curVal";
                if(field == 'curVal' && m.curVal == null && m.curScore == null)
                    return 'loading...';
				if(/Bool/i.test(m.kind)){
					// console.log(m)
					if(m.curVal == "true" || Number(m.curVal) == 1){
						return m.activeText || "True"
					} else {
						return m.inactiveText || "False"
					}
				}
				if(isNaN(Number(m.curVal))) return m.curVal
				if(m.chartData?.data.length >= 1){
					let lastVal = m.chartData.data[m.chartData.data.length - 1][1]
					return !isNaN(lastVal) ? Math.round(lastVal * 100)/100 : lastVal
				}
				return !isNaN(m[field]) ? Math.round(m[field] * 100)/100 : m[field]
			}
		},
		equipmentGearColor(){
			if(this.$store.state.theme!= null && this.$store.state.theme == "light"){
				return "#5E50FF";
			} else {
				return "#ffffff"
			}
		},
        showDonut(){
            return this.$store.state.site.aqEnabled == true || this.m.type == 'scoreEQ';
        },
        showNonWritable(){
            let show = true;
            if( this.$store.state.site.aqEnabled == false && ['scoreOA', 'scoreAQ', 'scoreOW', 'scoreAF'].includes(this.m.type)){
                show = false;
            }
            return show;
        },
	},
	watch : {
		'$props.timePeriod': {
			handler(){
				let startTime, endTime = null;
				switch(this.timePeriod){
					case "custom": {
						startTime = this.tpCustomStart
						endTime = this.tpCustomEnd
						break;
					}
					case "week": {
						startTime = moment().startOf('week').valueOf()
						break;
					}
					case "day": {
						startTime = moment().startOf('day').valueOf()
						break;
					}
					case "month": {
						startTime = moment().startOf('month').valueOf()
						break;
					}
					default: {
						startTime = moment().startOf('week').valueOf() 
						break;
					}
				}
				this.startTime = startTime;
				this.endTime = endTime;
                // console.log('props', this.timePeriod, new Date(startTime), new Date(endTime));
				this.loadTrendCharts(this.m);
			},
			immediate: false
		},
        "$store.state.daemonFetchTime" : {
			handler(){
				this.loadTrendCharts(this.m);
				this.aqCardIndicatorScoreLatest();
			},
			immediate: false
		}
	}
}
</script>
<style lang="scss">
	.material-tooltip {
		background-color: var(--bg-tooltip);
		border-radius: 8px;
		padding: 16px;
	}
	// .txt-resize {
	// 	// font-size: MAX(MIN(6vw,18px,)32px) !important;
	// }
</style>
<style lang="scss" scoped>
	.cls-1 {
		fill:none;
		stroke: var(--app-primary-txt);
		stroke-linecap:round;
		stroke-linejoin:round;
		stroke-width:2px;
	}
	.aqcard-navigate-wrap {
		cursor: auto;
        min-height: 113px;
		color: var(--app-tertiary-txt);
	}
	.aqcard-measurement-graph {
		cursor: pointer;
	}
	.top-lvl-aqcm {
		&:hover {
			background: rgba(0, 0, 0, 0.1);
		}
		.aqcard-navigate-wrap {
			cursor: pointer;
			&:hover {
				.aqcard-measurement-title {
					text-decoration: underline;
				}
			}

			@media only screen and (max-width: 601px) {
				.aqcard-measurement-title {
					text-decoration: underline;
				}
			}
		}
	}
	.writable-status-icons {
		.new-val-saving,
		.new-val-saved,
		.new-val-failed { display: none; }
		.new-val-saving { max-width: 24px; }
		.new-val-saved { color: #A9ECDE; }
		.new-val-failed { color: red; }
	}
	
	.writable {
		.writable-val {
			background: rgba(0,151,255,.4);
			border-radius: 12px;
			box-shadow: 0px 4px 4px 0px #00000040;
			cursor: pointer;
			padding: 0 12px;
            font-size: 28px;
		}
		.new-val-displaying {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.new-val-editing {
			display: none;

			.ev-num {
				background-color: var(--input-bg);
				border: 0;
				border-radius: 12px;
				box-sizing: border-box;
				color: var(--input-txt);
				padding: 5px;
				input {
					border: 0;
					margin: 0;
				}
			}
			.edit-val {
			margin: 0;
			padding: 0;
			}
			.new-val-edit-btns {
				margin-top: 8px;
				.edit-val-cancel {
					margin-right: 8px;
				}
				.edit-val-cancel,
				.edit-val-save {
					font-size: 12px;
					padding: 0 8px !important;
				}
				.haq-btn-primary {
					background: var(--btn-primary-bg)!important;
					border: 1px solid var(--btn-primary-border);
					color: var(--btn-primary-txt)!important;
				}
				.haq-btn-secondary {
					background: var(--btn-secondary-bg)!important;
					border: 1px solid var(--btn-secondary-border);
					color: var(--btn-secondary-txt)!important;
				}
			}
		}
		
	}
</style>